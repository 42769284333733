import { Fragment, useEffect, useRef, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../layout/Loading";
import { clearOrderErrors } from "../../../../store/actions/businessActions/businessOrders";
import axios from "axios";
import { getTripsFilterOrders } from "../../../../store/actions/businessActions/tripRequested";
import moment from "moment";
import { DirectionsRenderer, GoogleMap } from "@react-google-maps/api";

const TripRequested = () => {

    const dispatch = useDispatch();
    const allOrdersRefs = {}
    const reportTemplateRef = useRef(null);

    const [displayLoading, setDisplayLoading] = useState(false);
    const [tripDate, setTripDate] = useState('');
    const [active, setActive] = useState(null);
    const [activeChange, setActiveChange] = useState(false);

    const [show, setShow] = useState(false);
    const [map, setMap] = useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [mapDistance, setMapDistance] = useState("");
    const [mapTime, setMapTime] = useState("");
    const [mapLat, setMapLat] = useState("");
    const [mapLong, setMapLong] = useState("");

    const { userDetail } = useSelector((state) => state.userDetails);;
    const { tripsOrders, tripLoading, tripError, tripActive } = useSelector((state) => state.tripOrders);
    const { businessUserDetail } = useSelector((state) => state.businessUserDetails);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // handle map show
    const handleMapShow = (distance, time, lat, long, delAdd, pickAdd) => {
        setMapDistance(distance);
        setMapTime(time);
        setMapLat(lat);
        setMapLong(long);

        const distanceService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
            directionsRenderer.setMap(map);
            distanceService.route(
                {
                    origin: pickAdd,
                    destination: delAdd,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (response, status) => {
                if (status === 'OK') {
                   
                    setDirectionsResponse(response);
                    directionsRenderer.setDirections(response);

                    handleShow()

                } else {
                    alert('Error fetching distance:', status);
                }
                }
            ); 
    }

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setTripDate(formattedDate);

    }, []);
    
    
    const handleTripChange = (event) => {
        setTripDate(event.target.value);
    };

    // handle filter click
    const handleFilterClick = (event) => { 
        setDisplayLoading(true);
        setActive(event.target.id);
        setActiveChange(true);
    }

    // handle contact message click
    const handleMessgeCLick = (id) => {
        if(allOrdersRefs[id].style.display === "table-row"){
            allOrdersRefs[id].style.display = "";
            document.getElementById(id+"_orderfiltermsg").innerHTML = '';
        } else {

            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/getordersms/${id}`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
                },
            })
            .then(function (response) {
                if(response.data){
                    console.log(response)
                    var messageData = '';
                    response.data.map((item,i)=>(
                        messageData += '<div class="w-1/4 orders_messages_div"><p class="mb-0 font-semibold">Admin Notification</p><p class="mb-0">'+item.text+'</p><p class="mb-0">SMS TO '+item.contactPhone+'</p><p class="mb-0">'+(new Date(item.timestamp).toLocaleString("en-US"))+'</p></div>'
                    ))

                    document.getElementById(id+"_orderfiltermsg").innerHTML = '<td colSpan="11"><div class="flex py-1.5 px-3.5 gap-[15px]">'+messageData+'</div></td>';

                    allOrdersRefs[id].style.display = "table-row";
                }
            })
            .catch(function (response) {
                console.log(response);
                document.getElementById(id+"_orderfiltermsg").innerHTML = '';
            });
        }
    }

    // handle select date filter
    const handleSelectDateFilter = () => {
        setDisplayLoading(true)
        const formData = {
            "filter_value" : "bydate",
            "filter_date" : tripDate,
            "customer_obj_id": userDetail && userDetail.userId,
        }
    
        dispatch(getTripsFilterOrders(formData))
        setActive(null);
    }

    useEffect(() => {

        if (tripError) {
            console.log(tripError)
            dispatch(clearOrderErrors());
        }

        if(activeChange){
            setDisplayLoading(true)
            setActiveChange(false)
            const formData = {
                "filter_value" : active,
                "customer_obj_id": userDetail && userDetail.userId,
            }
    
            dispatch(getTripsFilterOrders(formData))
        }

        if(tripActive === null){
            setActive(null)
        } else{
            setActive(active)
        }

        if(tripLoading === false && tripsOrders && tripsOrders.status === 1){
            setDisplayLoading(false)
        }

    }, [dispatch, activeChange, active, userDetail, tripLoading, tripsOrders, tripError, tripActive, businessUserDetail])
    

    return(
        <>
            {displayLoading &&
                <Loading />
            }
            <div className="trip-content p-2.5 flex flex-col">
                <div className="custom_trips_table_outer block">
                    <div className="flex flex-col sm:flex-row items-center justify-around gap-[20px]">
                        <button className={`order_today ${active === 'today' ? "active" : ""}`} id="today" onClick={handleFilterClick}>Today Trips</button>
                        <button className={`order_today ${active === 'yesterday' ? "active" : ""}`} id="yesterday" onClick={handleFilterClick}>Yesterday Trips</button>
                        <button className={`order_today ${active === 'thisweek' ? "active" : ""}`} id="thisweek" onClick={handleFilterClick}>This Week Trips</button>
                        <button className={`order_today ${active === 'lastweek' ? "active" : ""}`} id="lastweek" onClick={handleFilterClick}>Last Week Trips</button>
                    </div>
                    <div className="hidden sm:flex items-center justify-center gap-[20px] mt-30px">
                        <div>
                            <input type="date" name="fs_trip_date" id="fs_trip_date" className="trip-date" value={tripDate} onChange={handleTripChange}/>
                        </div>
                        <button className="order_today green" onClick={() => handleSelectDateFilter()}>Select Date</button>
                    </div>
                </div>

                {tripsOrders && tripsOrders.status === 1 && (
                    <div ref={reportTemplateRef} className="pdf_generate">
                        <Table responsive className="trips-requested-orders w-full trips_data">
                            <thead>
                                <tr>
                                    <th style={{width: "10%"}}>Order Id</th>
                                    <th>Delivery Details of Customer</th>
                                    <th>PickUp Details</th>
                                    <th>Date Picked Up</th>
                                    <th>Time Order Placed</th>
                                    <th style={{width: "10%"}}>-</th>
                                    <th>Standard Delivery Fee</th>
                                </tr>
                            </thead>
                            <tbody>
                            
                                {tripsOrders.orderdetails.length >= 0 && tripsOrders.orderdetails.map((item,i) => (
                                    <Fragment key={i}>
                                        <tr className={`${item.yellow_order === 1 ? 'bg-yellow' : ''}`}>
                                            <td>{item.order_id}</td>
                                            <td>
                                                <p className="text-sm mb-0">
                                                    {item.delivery_firstname} <br/>
                                                    {item.delivery_address}
                                                </p>
                                            </td>
                                            <td>
                                                <p className="text-sm mb-0">
                                                    {item.pickup_location} <br/>
                                                    {item.pickup_address}
                                                </p>
                                            </td>
                                            <td>{moment(item.date_added).format("MM/DD/Y")}</td>
                                            <td>{moment(item.date_added).format("HH:mm a")}</td>
                                            <td data-html2canvas-ignore="true">
                                                <span className="text-primary-blue cursor-pointer" onClick={() => handleMapShow(item.order_mileage_distance, item.order_time_distance, item.delivery_lat, item.delivery_long, item.delivery_address, item.pickup_address )}>MAP</span> / <span className="text-primary-blue cursor-pointer" onClick={() => handleMessgeCLick(item._id)}>MSG</span>
                                            </td>
                                            <td>${item.final_total.toFixed(2)}</td>
                                        </tr>
                            
                                        {/* Orders messages */}
                                        <tr ref={ref => allOrdersRefs[item._id] = ref} className="orders-button-messages hidden" id={item._id+"_orderfiltermsg"} key={item._id+"_orderfiltermsg"}>
                                        </tr>
                            
                                    </Fragment>
                                ))}
                            
                                
                                {/* Total bottom rows */}
                                <tr className="font-bold">
                                    <td colSpan={4}></td>
                                    <td colSpan={2} style={{textAlign: "left"}}>Total Charge</td>
                                    <td>${tripsOrders.total_charge.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                )}
            </div>

            {/* Map Modal */}
            <Modal show={show} onHide={handleClose} centered={true} className="popup-map-modal" backdropClassName="dashboard-backdrop">

                <Modal.Header closeButton>
                    <h4 className="text-base font-bold font-alata mb-0 p-2.5 leading-none">Satellite Distance <br/> {mapDistance} Miles {mapTime} Minutes <br/>Locked Wheel Movement</h4>
                    <h4 className="text-base font-bold font-alata mb-0 p-2.5 leading-none">Latitude: {mapLat}<br/> Longitude: {mapLong}</h4>
                </Modal.Header>
                
                <Modal.Body className="relative">
                    <GoogleMap
                        center= {{lat: 40.0583, lng: 74.4057}}
                        zoom={7}
                        mapContainerStyle={{ width: "100%", height: "400px" }}
                        onLoad={(map) => setMap(map)}
                        options={{
                            zoomControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                            streetViewControl: false,
                            mapTypeId: "satellite",
                        }}
                    >
                        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
                    </GoogleMap>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TripRequested
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { clearOrderErrors, gteOrderInvoice, myInternalOrders } from "../../../../store/actions/businessActions/businessOrders";
import { useSnackbar } from "notistack";
import { io } from "socket.io-client";
import { Button, Modal, Table } from "react-bootstrap";
import { DirectionsRenderer, GoogleMap, Marker } from "@react-google-maps/api";
import monitizing from '../../../../assests/images/business/monitoring.gif';
import homeIcon from '../../../../assests/images/home-icon.png';
import restaurantIcon from '../../../../assests/images/restaurant.png';
import driverIcon from '../../../../assests/images/car.png';
import OrderInvoice from "../../../business/Dashboard/StatSection/OrderInvoice";

// Countdown Timer
const CountdownTimer = ({ initialTime, itemId, isCountdown, setIsCountdown }) => {
    // Parse the initialTime prop into minutes and seconds
    const parseTimeToSeconds = (timeStr) => {

        if(timeStr.split(':').map(Number).length === 3){

            const [hours, minutes, seconds] = timeStr.split(':').map(Number);
            return hours * 60 * 60 + minutes * 60 + seconds;

        } else if(timeStr.split(':').map(Number).length === 2){
            const [minutes, seconds] = timeStr.split(':').map(Number);
            return minutes * 60 + seconds;
        } else {
            const [seconds] = timeStr.split(':').map(Number);
            return seconds;
        }
    };
  
    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const secs = seconds % 60

        if(hours > 0){
            return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${secs < 10 ? `0${secs}` : secs}`;
        } else {
            return `${minutes < 10 ? `0${minutes}` : minutes}:${secs < 10 ? `0${secs}` : secs}`;
        }
        
    };
  
    const [timeLeft, setTimeLeft] = useState(parseTimeToSeconds(initialTime));
  
    useEffect(() => {
        if (timeLeft <= 0){ 
            // setDisplayCountdown(false)
            setIsCountdown(isCountdown.filter(item => item !== itemId));
            return
        };
  
      const intervalId = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
      
      return () => clearInterval(intervalId);
      
    }, [timeLeft, itemId, setIsCountdown, isCountdown]);
  
    return (
      <span>{formatTime(timeLeft)}</span>
    );
};


const ConsumerOrders = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { orders, error } = useSelector((state) => state.myInternalOrders);

    const { userDetail } = useSelector((state) => state.userDetails);

    const [show, setShow] = useState(false);
    const [map, setMap] = useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [mapDistance, setMapDistance] = useState("");
    const [mapTime, setMapTime] = useState("");
    const [mapLat, setMapLat] = useState("");
    const [mapLong, setMapLong] = useState("");
    const [orderId, setOrderId] = useState("");
    const [orderType, setOrderType] = useState("");
    const [displayInvoice, setDisplayInvoice] = useState(false);
    
    const [isCountdown, setIsCountdown] = useState([]);

    const [showDriver, setShowDriver] = useState(false);
    const [driverLat, setDriverLat] = useState(null);
    const [driverLng, setDriverLng] = useState(null);
    const [deliverLng, setDeliverLng] = useState(null);
    const [deliverLat, setDeliverLat] = useState(null);
    const [pickupLng, setPickupLng] = useState(null);
    const [pickupLat, setPickupLat] = useState(null);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const handleDriverClose = () => setShowDriver(false);
    const handleDriverShow = () => setShowDriver(true);
    
    // handle map show
    const handleMapShow = (distance, time, lat, long, delAdd, pickAdd) => {
        setMapDistance(distance);
        setMapTime(time);
        setMapLat(lat);
        setMapLong(long);

        const distanceService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
            directionsRenderer.setMap(map);
            distanceService.route(
                {
                    origin: pickAdd,
                    destination: delAdd,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (response, status) => {
                if (status === 'OK') {
                   
                    setDirectionsResponse(response);
                    directionsRenderer.setDirections(response);

                    handleShow()

                } else {
                    alert('Error fetching distance:', status);
                }
                }
            ); 
    }

    // handle driver track map show
    const handleDriverMap = (driLat, driLng, delLat, delLng, pickLat, pickLng) => {
        setDriverLat(parseFloat(driLat));
        setDriverLng(parseFloat(driLng));
        setDeliverLat(parseFloat(delLat));
        setDeliverLng(parseFloat(delLng));
        setPickupLat(parseFloat(pickLat));
        setPickupLng(parseFloat(pickLng));
        handleDriverShow()
    }

    // handle get invoice
    const getInvoice = (id, type) => {
        setOrderId(id);
        setOrderType(type);
        setDisplayInvoice(true);
        dispatch(gteOrderInvoice(id, type))
    }

    useEffect(() => {
        if(orders && orders.updatedResults && orders.updatedResults.length >= 1){
            setIsCountdown(orders.updatedResults.map(item => item.timediff > 0 ? item._id :'' ));
        }
    }, [orders])

    useEffect(() => {
        
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearOrderErrors());
        }
        
    }, [dispatch, error, enqueueSnackbar, userDetail]);

    useEffect(() => {
        if(userDetail && userDetail.userId){
            dispatch(myInternalOrders(userDetail.userId));
        }
        // eslint-disable-next-line
    }, [])

    // Socket
    useEffect(() => {
        const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
            path: "/api/socket.io", // Match the server-side custom path
        });
    
        socket.on('drivernow-orderlist', (newOrders) => {
            console.log(newOrders);
            if(userDetail && userDetail.userId){
                dispatch(myInternalOrders(userDetail.userId));
            }
        })
    
    }, [dispatch, userDetail])

    return(
        <div className="stat-section">
            <div id="gh_orders">
                {orders && orders.updatedResults && orders.updatedResults.length >= 1 && (
                    <Table responsive className="internal-orders-table mt-5">
                        <thead>
                            <tr>
                                <th className="w-[30%]">Order Information</th>
                                <th className="w-[10%]">Time</th>
                                <th className="w-[20%]">Driver Location / Track</th>
                                <th className="w-[10%]">ODDS</th>
                                <th className="w-[10%]">Est. Delivery</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.updatedResults.map((item, i) => (
                                <Fragment key={i}>
                                <tr key={item._id+"_order"}>
                                    <td>
                                        <h5 className="mb-0">PickUp Location</h5>
                                        <p className="mb-0 text-black font-bold">{item.pickup_location} {item.pickup_phone}</p>
                                        <p className="mb-0 text-gray-500">{item.pickup_address}</p>
                                        <h5 className="mb-0">Delivery Location</h5>
                                        <p className="mb-0 text-black font-bold">{item.delivery_firstname} {item.delivery_phone}</p>
                                        <p className="mb-0 text-gray-500">{item.delivery_address}</p>
                                    </td>
                                    <td className="text-center">
                                        {
                                        item.timediff < 0 ?
                                            <img src={item.timer_img} alt="Order Type" className="w-20 h-20 mx-auto" />
                                        :
                                            isCountdown.includes(item._id) ?
                                                <div>
                                                    <p className="mb-0">
                                                        <CountdownTimer initialTime={item.timediff_value} itemId={item._id} setIsCountdown={setIsCountdown} isCountdown={isCountdown} /> 
                                                    </p>
                                                    <button type="button" className="text-blue-500" onClick={() => handleMapShow(item.order_mileage_distance, item.order_time_distance, item.delivery_lat, item.delivery_long, item.delivery_address, item.pickup_address )}>Map</button>
                                                </div>

                                            :
                                                <img src={item.timer_img} alt="Order Type" className="w-20 h-20 mx-auto" />
                                        }

                                    </td>
                                    <td className={`text-center driver_map_block ${item.order_status_id === 6 ? 'bgpurple' : ''}`}>
                                        {item.driver_lat && item.driver_lat !== '' && item.driver_lat !== '0' && item.driver_long && item.driver_long !== '' && item.driver_long !== '0' ?
                                            <>
                                                <span className="text-gray-500">Dropping Off</span>
                                                <span className="driver_name">{item.driver_name}</span>
                                                <span className="driver_map">Click <span className="text-blue-500" onClick={() => handleDriverMap(item.driver_lat, item.driver_long, item.delivery_lat, item.delivery_long, item.pickup_lat, item.pickup_long)}>Here</span> for Tracking</span>
                                            </>
                                        :
                                            item.driver_info
                                        }
                                    </td>
                                    <td>
                                        {item.order_type === 4 || item.order_type === 3 || item.order_type === 6 || item.order_type === 2 ?
                                            <img src={item.order_type_img} alt="Order Type" className="h-[120px] mx-auto cursor-pointer" onClick={()=> getInvoice(item._id, item.order_type)} />
                                        :
                                            <img src={item.order_type_img} alt="Order Type" className="h-[120px] mx-auto" />
                                        }
                                    </td>
                                    <td className="text-center">
                                        <img src={monitizing} alt="MONITORING" className="h-20 mx-auto" />
                                    </td>
                                </tr>

                                </Fragment>
                            ))}
                        </tbody>
                    </Table>
                )}
                
            </div>

            {/* Order Invoice */}
            {displayInvoice &&
                <OrderInvoice id={orderId} orderType={orderType} setDisplayInvoice={setDisplayInvoice} />
            }

            {/* Map Modal */}
            <Modal show={show} onHide={handleClose} centered={true} className="popup-map-modal" backdropClassName="dashboard-backdrop">

                <Modal.Header closeButton>
                    <h4 className="text-base font-bold font-alata mb-0 p-2.5 leading-none">Satellite Distance <br/> {mapDistance} Miles {mapTime} Minutes <br/>Locked Wheel Movement</h4>
                    <h4 className="text-base font-bold font-alata mb-0 p-2.5 leading-none">Latitude: {mapLat}<br/> Longitude: {mapLong}</h4>
                </Modal.Header>
                
                <Modal.Body className="relative">
                    <GoogleMap
                        center= {{lat: 40.0583, lng: 74.4057}}
                        zoom={7}
                        mapContainerStyle={{ width: "100%", height: "400px" }}
                        onLoad={(map) => setMap(map)}
                        options={{
                            zoomControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                            streetViewControl: false,
                            mapTypeId: "satellite",
                        }}
                    >
                        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
                    </GoogleMap>
                </Modal.Body>
            </Modal>

            {/* Driver Location Map Modal */}
            <Modal size="lg" show={showDriver} onHide={handleDriverClose} centered={true} className="popup-map-modal" backdropClassName="dashboard-backdrop">

                <Modal.Header closeButton>
                    <h4 className="text-base font-medium font-alata mb-0 p-2.5 text-center w-full">Map</h4>
                </Modal.Header>
                
                <Modal.Body className="relative">
                    <GoogleMap
                        center= {{lat: driverLat, lng: driverLng}}
                        zoom={9}
                        mapContainerStyle={{ width: "100%", height: "400px" }}
                        onLoad={(map) => setMap(map)}
                        options={{
                            zoomControl: true,
                            mapTypeControl: true,
                            fullscreenControl: false,
                            streetViewControl: false,
                        }}
                    >
                        {deliverLat && deliverLng &&
                            <Marker icon={homeIcon} position={{lat: deliverLat, lng: deliverLng}} />
                        }

                        {driverLat && driverLng &&
                            <Marker icon={driverIcon} position={{lat: driverLat, lng: driverLng}} />
                        }

                        {pickupLat && pickupLng &&
                            <Marker icon={restaurantIcon} position={{lat: pickupLat, lng: pickupLng}} />
                        }
                        
                    </GoogleMap>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDriverClose}>Close</Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default ConsumerOrders
import MetaData from "../../../layout/MetaData"
import './ConsumerStyle.css';
import { TabProvider } from "../../business/Dashboard/TabContext"
import ConsumerDashboardHeader from "./ConsumerDashboardHeader"
import DriverNowConsumer from "./DriverNowConsumer/DriverNowConsumer"
import FooterButtons from "./FooterButtons/FooterButtons"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { clearErrors, detailBusinessUser, emptyBusinessSession } from "../../../store/actions/businessActions/businessUsers";
import ConsumerOrders from "./ConsumerOrders/ConsumerOrders";

const ConsumerDashboard = () => {

    const dispatch = useDispatch();
    const { userDetail } = useSelector((state) => state.userDetails);

    const { error } = useSelector((state) => state.businessUserDetails);

    useEffect(() => {
    
        if(userDetail && userDetail.userId){
               
            var userDetails = {
                obj_id: userDetail.userId,
                email: userDetail.userEmail,
                customer_id: userDetail.customerId,
            }
            dispatch(detailBusinessUser(userDetails))
        }
    
        if(error){
            dispatch(emptyBusinessSession())
            dispatch(clearErrors())
        }
            
    }, [dispatch, userDetail, error])

    return(
        <>
            <MetaData
                title={"Consumer Dashbaord"}
            />
            
            <section className="consumer-dashboard business-dashboard sales-pages">
                {/* header */}
                <ConsumerDashboardHeader />

                {/* main section */}
                <div className="max-w-screen-2xl mx-auto px-12px">
                    <div className="main-inner p-20px">
                        <TabProvider>

                            {/* Driver Now Section */}
                            <DriverNowConsumer />

                            {/* Consumer Orders */}
                            <ConsumerOrders />

                            {/* Footer Section */}
                            <FooterButtons />
                        </TabProvider>
                    </div>
                </div>
            </section>
            
        </>
    )
}

export default ConsumerDashboard